import { map } from 'rxjs/operators';
import { CreditCardService } from '../credit-card.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, inject, effect, signal, computed } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TopService } from '../../../shared/services/top.service';
import { ICreditCard, IBillInfo, ICreditCardRequest } from './../../../shared/interfaces/ICreditCard';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { CartService } from '../../cart/cart.service';
import { UserService } from '../../../user/user.service';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { NgIf, NgFor, NgClass, UpperCasePipe } from '@angular/common';


@Component({
    selector: 'app-credit-card-list',
    templateUrl: './credit-card-list.component.html',
    styleUrls: ['./credit-card-list.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, FormsModule, CreditCardComponent, UpperCasePipe]
})
export class CreditCardListComponent implements OnInit, OnDestroy {
  @Output() closeCreditCardListModal = new EventEmitter();

  verificationForm: FormGroup;
  calledFrom: string;
  showCreditCardModal = this.creditCardService.showCreditCardModal;
  creditCardList = this.creditCardService.creditCardList;
  creditCardListLoading = this.creditCardService.creditCardListLoading;
  selectedCreditCard = this.creditCardService.selectedCreditCard;
  isCreditCardDeclined = this.cartService.isCreditCardDeclined;
  creditCardIdToEdit = signal<string>(null);
  verifyCardCodeLoading = signal(false);
  loading = computed(() => { return this.creditCardListLoading() || this.verifyCardCodeLoading() });

  constructor(
    public topService: TopService,
    private router: Router,
    public creditCardService: CreditCardService,
    public cartService: CartService,
    private formB: FormBuilder,
    private userService: UserService
  ) {
    this.initializeEffects();
  }

  ngOnInit() {
    this.calledFrom = this.creditCardService.verifyUrl();

    // modal in cart
    if (this.calledFrom !== 'user-profile') {
      document.documentElement.classList.add('gg-modal');
      if (this.topService.isMobile) {
        document.documentElement.classList.add('is-modal');
      }
    } else {
      // user profile route
      this.getCreditCardList();
    }

    this.createVerificationForm();
  }

  ngOnDestroy() {
    document.documentElement.classList.remove('gg-modal');
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }

  private initializeEffects(): void {
    effect(() => {
      if (!this.creditCardListLoading() && this.creditCardList().length === 0 && this.calledFrom === 'user-profile') {
        setTimeout(() => {
          this.showCreditCard();
        }, 0);
      }
    });
  }

  createVerificationForm() {
    this.verificationForm = this.formB.group({
      code: ['', [Validators.minLength(3), Validators.maxLength(4)]]
    });
  }

  getCreditCardList(): void {
    this.creditCardService.getCreditCardList();
  }

  chooseCard(card: ICreditCard): void {
    if (this.calledFrom === 'cart') {
      if (sessionStorage.getItem('user_role') !== 'BUYER') {
        this.toggleVerificationForm(card, this.creditCardList());
      } else {
        this.selectedCreditCard.set(card);
        this.isCreditCardDeclined.set(false);
        this.closeCreditCardListModal.emit();
      }
    }
  }

  toggleVerificationForm(card, list): void {
    this.verificationForm.reset();
    list.forEach(cc => {
      card.valid = true;
      card.verficationCode = '';
      if (cc.id === card.id) {
        card.showVerificationForm = !card.showVerificationForm;
      } else {
        cc.showVerificationForm = false;
      }
    });
  }

  verifyCardCode(card: ICreditCard) {
    this.verifyCardCodeLoading.set(true);
    this.creditCardService.verifyCreditCardCode(card['verficationCode'], card.id)
      .subscribe(
        (data) => {
          this.verifyCardCodeLoading.set(false);
          this.selectedCreditCard.set(card);
          this.isCreditCardDeclined.set(false);
          this.toggleVerificationForm(card, this.creditCardList());
          card['valid'] = true;
          card['verficationCode'] = '';
          this.closeCreditCardListModal.emit();
        },
        (err) => {
          console.log('Error trying to update a credit card: ' + err);
          card['valid'] = false;
          this.verifyCardCodeLoading.set(false);
        });
  }

  showCreditCard(creditCardId?: string): void {
    this.creditCardIdToEdit.set(creditCardId ? creditCardId : null);
    this.creditCardService.showCreditCardModal.set(true);
  }

  closeCreditCardModal(): void {
    this.creditCardService.showCreditCardModal.set(false);
    if (this.creditCardList().length === 0 && this.calledFrom === 'cart') {
      this.closeCreditCardListModal.emit();
    }
  }

  delete(creditCardId: string) {
    this.creditCardService.deleteCreditCard(creditCardId);
  }

}
