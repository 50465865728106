import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ICreditCard, ICreditCardRequest } from './../../shared/interfaces/ICreditCard';
import { Observable, of } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { environment } from './../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  creditCardList = signal<ICreditCard[]>([]);
  creditCardListLoading = signal<boolean>(false);
  deleteCreditCardLoading = signal<boolean>(false);
  selectedCreditCard = signal<ICreditCard>(null);
  showCreditCardModal = signal<boolean>(false);

  constructor(private http: HttpClient, private router: Router) { }

  getCreditCardList(): void {
    const url = `${environment.notificationCenterUrl}/api/creditcards`;
    this.creditCardListLoading.set(true);
    this.http.get<{ cards: any[] }>(url, { headers: this.headers }).pipe(
      catchError(error => {
        console.error('Error fetching credit cards:', error);
        this.creditCardListLoading.set(false);
        return of({ cards: [] });
      })
    ).subscribe((res) => {
      const creditCards = res.cards?.map(_cc => ({
        id: _cc.cardId,
        nickname: _cc.cardName,
        type: _cc.cardType,
      } as ICreditCard)) || [];

      this.creditCardListLoading.set(false);
      this.creditCardList.set(creditCards);
    });
  }

  getCreditCard(creditCardId: string): Observable<ICreditCardRequest> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${creditCardId}`;
    return this.http.get<ICreditCardRequest>(url, { headers: this.headers });
  }

  createCreditCard(cardRequest: ICreditCardRequest): Observable<ICreditCard> { // save and return the card
    const url = environment.notificationCenterUrl + '/api/creditcards/create';
    let card: ICreditCard = null;
    return this.http.post(url, cardRequest, { headers: this.headers }).pipe(
      map((cardResponse: any) => {
        if (cardResponse) {
          card = {
            id: cardResponse.cardId,
            nickname: cardResponse.cardName,
            type: cardResponse.cardType,
            error: cardResponse.error
          } as ICreditCard;
        }
        return card;
      })
    );
  }

  updateCreditCard(cardRequest: ICreditCardRequest, cardId: string): Observable<ICreditCard> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${cardId}/update`;
    let card: ICreditCard = null;
    return this.http.put(url, cardRequest, { headers: this.headers }).pipe(
      map((cardResponse: any) => {
        if (cardResponse) {
          card = {
            id: cardResponse.card.cardId,
            nickname: cardResponse.card.cardName,
            type: cardResponse.card.cardType,
            error: cardResponse.error
          } as ICreditCard;
        }
        return card;
      })
    );
  }

  deleteCreditCard(creditCardId: string): void {
    const url = `${environment.notificationCenterUrl}/api/creditcards/${creditCardId}/delete`;
    this.deleteCreditCardLoading.set(true);
    
    this.http.delete(url, { headers: this.headers }).pipe(
      tap({
        next: (res) => {
          this.creditCardList.update((cards) => cards.filter(card => card.id !== creditCardId));
          if (this.creditCardList().length === 0 || ( this.selectedCreditCard() && this.selectedCreditCard().id === creditCardId)) {
            this.selectedCreditCard.set(null);
          }
        },
        error: (error) => {
          console.error('Error deleting credit card:', error);
        },
        complete: () => {
          this.deleteCreditCardLoading.set(false);
        }
      })
    ).subscribe();

  }

  verifyCreditCardCode(ccv: string, cardId: string): Observable<any> {
    const url = environment.notificationCenterUrl + `/api/creditcards/${cardId}/validation`;
    const param = { 'CardCCV': ccv };
    return this.http.post(url, param, { headers: this.headers });
  }

  verifyUrl(): string {
    let url = '';
    if (this.router.url === '/user/credit_cards') {
      url = 'user-profile';
    } else if (['/cart', '/cart?updatingOrder=Y'].includes(this.router.url)) {
      url = 'cart';
    }
    return url;
  }

  checkCreditCardListEmpty() {
    const url = environment.notificationCenterUrl + '/api/creditcards/count';
    return this.http.get(url, { headers: this.headers });
  }

}
