<div class="payment-modal" [class.mobile]="topService.isMobile">
  <div class="payment-modal-dialog animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
    <div class="payment-modal-content">
      <div class="payment-modal-header">
        <span *ngIf="!creditCardId">New Payment Method</span>
        <span class="payment-modal-header__close" (click)="close()">
          <img src="../../../../assets/images/close.svg" width="24" height="24" alt="close">
        </span>
      </div>

      <div class="payment-modal-body">
        <form [formGroup]="formCredit" class="form">
          <div class="grid-row" *ngIf="creditCardId">
            <div class="grid-col">
              <div class="control">
                <div class="control__label"></div>
                <div>
                  <input type="text" placeholder="My visa card" formControlName="nickname" autocomplete="off"
                    [maxLength]="250" [class.required]="formErrors.nickname.required">
                </div>
              </div>
            </div>
          </div>

          <div class="form-wizard-step _step-1">
            <div class="form-header">
              Bill to
            </div>

            <div formGroupName="billInfo">
              <div class="grid-row">
                <div class="grid-col _half">
                  <div class="control">
                    <div class="control__label">First name</div>
                    <div>
                      <input type="text" placeholder="John" formControlName="firstName" autocomplete="off"
                        [maxLength]="150" [class.required]="formErrors.firstName.required">
                    </div>
                  </div>
                </div>
                <div class="grid-col _half">
                  <div class="control">
                    <div class="control__label">Last name</div>
                    <div>
                      <input type="text" placeholder="Smith" formControlName="lastName" autocomplete="off"
                        [maxLength]="150" [class.required]="formErrors.lastName.required">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col">
                  <div class="control">
                    <div class="control__label">Company Name</div>
                    <div>
                      <input type="text" placeholder="ABC Company" formControlName="companyName" autocomplete="off"
                        [maxLength]="250" [class.required]="formErrors.companyName.required">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col">
                  <div class="control">
                    <div class="control__label">Address</div>
                    <div>
                      <input type="text" placeholder="382 Jackson St." formControlName="address" autocomplete="off"
                        [maxLength]="500" (onSelect)="setAddress($event, 'formBill')"
                        [class.required]="formErrors.address.required">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col">
                  <div class="control">
                    <div class="control__label">Apartment, suite, etc.</div>
                    <div>
                      <input type="text" placeholder="123456" formControlName="aptSuite" autocomplete="off"
                        [maxLength]="10" [class.required]="formErrors.aptSuite.required">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col _third _mobile-full">
                  <div class="control">
                    <div class="control__label">City</div>
                    <div>
                      <input type="text" placeholder="Chicago" formControlName="city" autocomplete="off"
                        [maxLength]="50" [class.required]="formErrors.city.required">
                    </div>
                  </div>
                </div>
                <div class="grid-col _third _mobile-half">
                  <div class="control">
                    <div class="control__label">State / Province</div>
                    <div>
                      <!-- <input type="text" placeholder="Illinois" formControlName="state" autocomplete="off"
                        [maxLength]="50" [class.required]="formErrors.state.required"> -->
                        <select formControlName="state" placeholder="Illinois" [class.required]="formErrors.state.required">
                          <option *ngFor="let state of states" [ngValue]="state.name">
                            {{ state.name }}
                          </option>
                        </select>
                    </div>
                  </div>
                </div>
                <div class="grid-col _third _mobile-half">
                  <div class="control">
                    <div class="control__label">ZIP / Postal Code</div>
                    <div>
                      <input type="text" placeholder="123456" formControlName="zipCode" autocomplete="off" inputmode="numeric" mask="{{mask.zipCode}}"
                        [maxLength]="10" [class.required]="formErrors.zipCode.required">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col _half _mobile-full">
                  <div class="control">
                    <div class="control__label">Email</div>
                    <div>
                      <input type="email" placeholder="company@mail.com" formControlName="email" autocomplete="off"
                        [maxLength]="50" [class.required]="formErrors.email.validaEmailExtension === false">
                    </div>
                  </div>
                </div>
                <div class="grid-col _half _mobile-full">
                  <div class="control">
                    <div class="control__label">Phone Number</div>
                    <div>
                      <input type="tel" placeholder="(123) 4567-890" formControlName="phoneNumber" autocomplete="off"
                        [maxLength]="50" mask="{{mask.phoneNumber}}"  [validation]="false"
                        [class.required]="formErrors.phoneNumber.required || formErrors.phoneNumber.validPhoneNumberLength === false"
                        >
                      <!-- <span class="has-error" *ngIf="formErrors.phoneNumber.minlength || formErrors.phoneNumber.maxlength"> -->
                      <!-- <span class="has-error" *ngIf="formErrors.phoneNumber.validPhoneNumberLength === false">
                        Invalid Length!
                      </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div class="form-header _top-indent">
              Credit Card
              <span>We do not accept international credit cards.</span>
            </div>

            <div class="grid-row" *ngIf="!creditCardId">
              <div class="grid-col">
                <div class="control">
                  <div class="control__label">Card Nickname</div>
                  <div>
                    <input type="text" placeholder="My visa card" formControlName="nickname" autocomplete="off"
                      [maxLength]="250" [class.required]="formErrors.nickname.required">
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="control">
                  <div class="control__label">Card Number</div>
                  <div class="control-cc-icons">
                    <input type="text" placeholder="1234 5678 9012 3456" *ngIf="!creditCardId" formControlName="number"
                      autocomplete="cc-number" inputmode="numeric" [maxLength]="50" mask="{{mask.cardNumber}}"
                      [class.required]="formErrors.number.required || formErrors.number.validCreditCardNumber === false" digitOnly>

                    <input type="text" [disabled]="true" *ngIf="creditCardId"
                      value="{{'**** **** **** ' + creditCardLastDigits }}">

                    <ul class="cc-icons">
                      <li *ngIf="showCreditCardImage('visa')">
                        <img src="../../../../assets/images/icons/credit-cards/visa.svg" width="32" height="32"
                          alt="visa">
                      </li>
                      <li *ngIf="showCreditCardImage('mastercard')">
                        <img src="../../../../assets/images/icons/credit-cards/mastercard.svg" width="32" height="32"
                          alt="">
                      </li>
                      <li *ngIf="showCreditCardImage('american-express')">
                        <img src="../../../../assets/images/icons/credit-cards/amex.svg" width="32" height="32" alt="">
                      </li>
                    </ul>
                    <span *ngIf="!creditCardId">
                      <span class="has-error" *ngIf="formErrors.number.validCreditCardType === false">
                        Unrecognized
                      </span>
                      <span class="has-error" *ngIf="formErrors.number.validCreditCardUnique === false">
                        Ambiguous
                      </span>
                      <span class="has-error" *ngIf="formErrors.number.validCreditCardLength === false">
                        Invalid Credit Card Length
                      </span>
                      <span class="has-error" *ngIf="formErrors.number.validCreditCardLuhn === false">
                        Invalid Credit Card Luhn
                      </span>
                      <span class="has-error" *ngIf="formErrors.number.validCreditCardPattern === false">
                        Invalid Credit Format
                      </span>
                    </span>

                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col">
                <div class="control">
                  <div class="control__label">Card Holder Name</div>
                  <div>
                    <input type="text" placeholder="JOHN SMITH" formControlName="holderName" autocomplete="cc-name" *ngIf="!creditCardId"
                      [maxLength]="250" [class.required]="formErrors.holderName.required">

                      <input type="text" *ngIf="creditCardId" [disabled]="true" value="{{ formCredit?.value?.holderName }}">
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-row">
              <div class="grid-col _third">
                <div class="control">
                  <div class="control__label">Exp Date</div>
                  <div>
                    <input type="text" placeholder="MM / YY" formControlName="expirationDate" autocomplete="cc-exp" inputmode="numeric"
                      [maxLength]="50" mask="{{mask.expirationDate}}" digitOnly
                      [class.required]="formErrors.expirationDate.required || formErrors.expirationDate.validCreditCardExpDate === false" >
                  </div>
                </div>
              </div>
              <div class="grid-col _third" *ngIf="!creditCardId">
                <div class="control">
                  <div class="control__label">CCV</div>
                  <div>
                    <input type="text" placeholder="{{mask.ccv === '0000' ? '1234' : '123'}}" formControlName="ccv"
                      autocomplete="cc-csc" inputmode="numeric" mask="{{mask.ccv}}" [maxLength]="4" digitOnly
                      [class.required]="formErrors.ccv.required || formErrors.ccv.validCreditCardCCV === false">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="payment-modal-footer">
        <div class="grid-row">
          <div class="grid-col _half">
            <button type="button" class="_secondary" (click)="close()">
              Cancel
            </button>
          </div>
          <div class="grid-col _half">
            <button type="button" class="_primary"  (click)="save()" [disabled]="loading()">            
              <span *ngIf="!creditCardId">Save</span>
              <span *ngIf="creditCardId">Update</span>
            </button>
          </div>
        </div>
      </div>

      <div class="errors-summary" *ngIf="error">
        <div class="errors-summary__title">Sorry your credit card was declined.</div>
        <div class="errors-summary__txt">Please use a different card or select a alternative payment method.</div>
      </div>
    </div>
  </div>
</div>

