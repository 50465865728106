import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2, computed, effect, signal } from '@angular/core';
import { UserService } from '../../../user/user.service';
import * as modelsU from '../../../shared/interfaces/model';
import { CartService } from '../cart.service';
import { TopService } from '../../../shared/services/top.service';
import { ICreditCard } from '../../../shared/interfaces/ICreditCard';
import { CreditCardService } from '../../credit-cards/credit-card.service';
import { PaymentTypeEnum } from '../../enums/PaymentTypeEnum';
import * as models from '../../interfaces/model';
import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { CreditCardComponent } from '../../credit-cards/credit-card/credit-card.component';
import { CreditCardListComponent } from '../../credit-cards/credit-card-list/credit-card-list.component';
import { AddressComponent } from '../../address/address.component';
import { AddressMobileComponent } from '../../address/address-mobile/address-mobile.component';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ExportToExcelComponent } from '../../export-to-excel/export-to-excel.component';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-proceed-to-checkout-top',
    templateUrl: './proceed-to-checkout-top.component.html',
    styleUrls: ['./proceed-to-checkout-top.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ExportToExcelComponent, CarouselModule, FormsModule, AddressMobileComponent, AddressComponent, CreditCardListComponent, CreditCardComponent, DatePipe, CustomCurrencyPipe]
})
export class ProceedToCheckoutTopComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() order: models.IOrder;
  @Input() showAddToExistingOrder: boolean;
  @Input() isMobile: boolean;
  @Input() isCartEmptyAndCompleted: boolean;
  @Input() terms: string;
  @Input() prepay: boolean;
  @Input() shipping: modelsU.IAddress[];
  @Input() billing: modelsU.IAddress[];
  @Input() erroronline: boolean;
  @Input() Baddress: modelsU.IAddress;
  @Input() Saddress: modelsU.IAddress;
  @Input() selectedOrderNumber: number;
  @Input() selectedDocNumber: number;
  @Input() completed: boolean;
  @Input() isCart: boolean;
  @Input() totalUnits: number;
  @Input() totalAmount: number;
  @Input() cartListLength: number;
  @Input() findAllErrors: boolean;
  @Input() isOutOfStockAndSelectedOrderNumber: boolean;
  @Input() loading: boolean;
  @Input() isOutOfStock: boolean;
  @Input() isCreditCardAllowed: boolean;
  @Output() selectedAddress = new EventEmitter<modelsU.IAddressEmitter>();
  @Output() choosePaymentTypeEmitter = new EventEmitter<string>();
  @Output() gotoCheckoutEmitter = new EventEmitter();
  @Output() placeNewEmitter = new EventEmitter();
  @Output() toggleConfirmModalEmitter = new EventEmitter();
  @Output() exportToExcelEmmiter = new EventEmitter();

  @ViewChild('inputTerms') inputTerms: ElementRef;
  @ViewChild('inputPrepay') inputPrepay: ElementRef;
  @ViewChild('inputCredit') inputCredit: ElementRef;

  PaymentTypeEnum = PaymentTypeEnum;
  isAddressOpen: boolean = false;
  cardListOpen: boolean = false;
  cardListClose = signal(false);
  addressType: string;
  currentAddress: string;
  addresses: modelsU.IAddress[];
  address: modelsU.IAddress;
  isUpdatingCreditOrder = false;
  previousPaymentType: PaymentTypeEnum;

  showCreateCCModal = false;
  creditCardList = this.creditCardService.creditCardList;
  selectedCreditCard = this.creditCardService.selectedCreditCard;
  creditCardListLoading = this.creditCardService.creditCardListLoading;
  isCreditCardDeclined = this.cartService.isCreditCardDeclined;
  effectsLoaded = false;


  constructor(
    public cartService: CartService,
    public topService: TopService,
    public creditCardService: CreditCardService,
    private changeDetectorRef: ChangeDetectorRef,
    public sharedService: SharedService,
    public userService: UserService
  ) {
    this.initializeEffects();
   }

  ngOnInit() {
    this.setPayment();
  }

  private initializeEffects(): void {
    
    effect(() => {
      const creditCardsQty = this.creditCardList().length;
      if (!this.effectsLoaded) {
        this.effectsLoaded = true;
        return;
      }      
      if (!this.creditCardListLoading() && this.effectsLoaded) {
        this.showCreateCCModal = (creditCardsQty === 0);
        this.cardListOpen = !this.showCreateCCModal;
      }
    });
  }

  setPayment() {
    if (this.order) {
      if (this.order.payment.id) {
        this.cartService.paymentType = PaymentTypeEnum.credit;
        this.isUpdatingCreditOrder = true;
        this.selectedCreditCard.set(
          {
            nickname: this.order.payment.cardName,
            creditCardId: this.order.payment.creditCardId
          } as ICreditCard
        );
      } else if (this.terms === 'terms') {
        this.cartService.paymentType = PaymentTypeEnum.terms;
      } else if (this.terms === 'prepay') {
        this.cartService.paymentType = PaymentTypeEnum.prepay;
      }
    } else if (this.terms === 'prepay' && !this.isCreditCardAllowed) {
      this.cartService.paymentType = PaymentTypeEnum.prepay;
    }

  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.selectedCreditCard.set(null);
    this.cartService.paymentType = null;
    this.isCreditCardDeclined.set(false);
  }

  onChoosePaymentType(element = null, event = null) {
    if (this.cartService.paymentType !== PaymentTypeEnum.credit) {
      this.previousPaymentType = this.cartService.paymentType;
    }

    this.cartService.isCreditCardDeclined.set(false);
    if (this.topService.isMobile) {
      event.preventDefault();
      if (element.id === 'Terms' && this.terms === 'prepay' || this.isUpdatingCreditOrder) { return; }

      element.checked = true;
      this.cartService.paymentType = element.attributes.val.value;
    }

    if (this.cartService.paymentType === PaymentTypeEnum.credit && !this.selectedCreditCard()) {
      this.changeCreditCard();
    }
    this.choosePaymentTypeEmitter.emit(this.cartService.paymentType);
  }

  onSelectedAddress(event: modelsU.IAddressEmitter): void {
    this.selectedAddress.emit(event);
  }

  gotoCheckout() {
    this.gotoCheckoutEmitter.emit();
  }

  placeNew() {
    if (this.cartService.paymentType) {
      this.placeNewEmitter.emit();
    }
  }

  toggleConfirmModal() {
    this.toggleConfirmModalEmitter.emit();
  }

  exportToCSV(): void {
    this.exportToExcelEmmiter.emit();
  }

  closeAddress() {
    this.isAddressOpen = false;
  }

  changeCreditCard() {
    this.cartService.paymentType = PaymentTypeEnum.credit;
    this.getCreditCardList();
  }

  closeCreditCardModal(): void {
    this.showCreateCCModal = false;

    if (this.creditCardService.creditCardList.length === 0) {
      this.closeCardListModal()
    }
  }

  getCreditCardList(): void {
    this.creditCardService.getCreditCardList();
  }

  selectAddress(type: string) {
    if (type == 'billing') {
      this.addressType = type;
      this.currentAddress = this.Baddress.addressCode;
      this.addresses = this.billing;
      this.address = this.Baddress;
      this.isAddressOpen = true;

      return;
    } else if (type == 'shipping') {
      this.addressType = type;
      this.currentAddress = this.Saddress.addressCode;
      this.addresses = this.shipping;
      this.address = this.Saddress;
      this.isAddressOpen = true;
      return;
    } else {
      return;
    }
  }

  closeCardListModal() {
    if (this.cartService.paymentType === PaymentTypeEnum.credit && !this.selectedCreditCard()) {
      this.cartService.paymentType = this.previousPaymentType;
      this.choosePaymentTypeEmitter.emit(this.cartService.paymentType);
    }
    setTimeout(() => {
      this.cardListOpen = false;
      this.cardListClose.set(false);
    }, 200);
  }

  showPaymentTypeOption(option: PaymentTypeEnum): boolean {
    switch (option) {
      case PaymentTypeEnum.terms:
        if (this.terms === 'prepay') {
          return false;
        }
        break;
      case PaymentTypeEnum.prepay:
        if (this.order && this.cartService.paymentType !== PaymentTypeEnum.prepay) {
          return false;
        }
        break;
      case PaymentTypeEnum.credit:
        if ((!this.order && !this.isCreditCardAllowed) || (this.order && this.cartService.paymentType !== PaymentTypeEnum.credit)) {
          return false;
        }
        break;
    }
    return true;
  }

}
