<div class="checkout-wrap" *ngIf="!showAddToExistingOrder">
  <div class="checkout-top" *ngIf="cartListLength > 0" [ngClass]="{'hide': cartListLength === 0 || completed }">

    <div class="checkout-top__header">
      <h1>Checkout</h1>
    </div>

    <div class="checkout-top__export">
      <app-export-to-excel *ngIf="!topService.isMobile" [data]="cartItems" [isCart]="true"
        (exportToExcelEmitter)="exportToCSV()"></app-export-to-excel>

      <div *ngIf="topService.isMobile" class="checkout-top__export">
        <button (click)="exportToCSV()">
          <i>
            <img src="../../../../assets/images/icons/item-filter/excel.svg" width="24" height="24" />
          </i>
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile start -->
  <div class="checkout-boxes __mobile" *ngIf="topService.isMobile" [class.hide]="isCartEmptyAndCompleted">

    <owl-carousel-o [options]="{
      items: 1,
      stagePadding: 35,
      loop: true,
      startPosition: 2,
      margin:8,
      dots: true,
      navigation: false
    }">

    <ng-template carouselSlide class="checkout-box">
      <div class="checkout-box-wrap">
        <div class="checkout-box-title">
          <span> BILL TO ADDRESS </span>
        </div>
        <div class="checkout-box-edit" *ngIf="billing && billing.length>1" (click)="selectAddress('billing')"></div>
        <div class="checkout-box-address">
          <span class="_name"> {{Baddress.addressCode}} </span>
          <span>{{Baddress.address1}}</span>
          <span>{{Baddress.address2}}</span>
          <span>{{Baddress.city}} {{Baddress.state}} {{Baddress.country}} {{Baddress.zip}}</span>
        </div>
      </div>
    </ng-template>

      <ng-template carouselSlide class="checkout-box">
        <div class="checkout-box-wrap">
          <div class="checkout-box-title">
            <span> SHIP TO ADDRESS </span>
          </div>
          <div class="checkout-box-edit" *ngIf="shipping && shipping.length>1" (click)="selectAddress('shipping')">
          </div>
          <div class="checkout-box-address">
            <span class="_name"> {{Saddress.addressCode}} </span>
            <span>{{Saddress.address1}}</span>
            <span>{{Saddress.address2}}</span>
            <span>{{Saddress.city}} {{Saddress.state}} {{Saddress.country}} {{Saddress.zip}}</span>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide class="checkout-box">
        <!-- Class invalid if no payment method selected -->
        <div class="checkout-box-wrap" [class.invalid]="!cartService.paymentType" [class.declined]="isCreditCardDeclined() && topService.isMobile">

          <div class="checkout-box-title">
            <span> Payment Type </span>
            <span class="on-invalid"> Select One </span>
          </div>

          <div class="checkout-box-radio">
            <div class="checkout-radio" *ngIf="showPaymentTypeOption(PaymentTypeEnum.terms)" (click)="onChoosePaymentType(inputTerms, $event)" >
              <div class="checkout-radio-wrap" (change)="onChoosePaymentType()">
                <input #inputTerms type="radio" id="Terms" name="paymentType" [value]="'terms'" [(ngModel)]="cartService.paymentType" val="terms">
                <label for="Terms"></label>
              </div>
              <label for="Terms" class="checkout-radio__label">Terms</label>
            </div>

            <div class="checkout-radio" *ngIf="showPaymentTypeOption(PaymentTypeEnum.prepay)" (click)="onChoosePaymentType(inputPrepay, $event)" >
              <div class="checkout-radio-wrap">
                <input #inputPrepay type="radio" id="Prepay" name="paymentType" [value]="'prepay'" [(ngModel)]="cartService.paymentType" val="prepay">
                <label for="Prepay"></label>
              </div>
              <label for="Prepay" class="checkout-radio__label">Pre-pay</label>
            </div>

            <div *ngIf="showPaymentTypeOption(PaymentTypeEnum.credit)" class="checkout-radio" (click)="onChoosePaymentType(inputCredit, $event)">
              <div class="checkout-radio-wrap">
                <input #inputCredit type="radio" id="Credit" name="paymentType" [value]="'credit'" [(ngModel)]="cartService.paymentType" val="credit">
                <label for="Credit"></label>
              </div>
              <label for="Credit" class="checkout-radio__label">Credit Card
                <span>
                  {{selectedCreditCard()?.nickname}}
                </span>
              </label>
              <a *ngIf="creditCardList().length && !isUpdatingCreditOrder && cartService.paymentType === PaymentTypeEnum.credit"
              role="button" class="checkout-radio__link" (click)="changeCreditCard()">
                <span>Change</span>
              </a>
              <a *ngIf="userService.isCreditCardListEmpty === 0 && !isUpdatingCreditOrder"
              role="button" class="checkout-radio__link" (click)="changeCreditCard()">
                <span class="badge-new">new</span>
              </a>
            </div>

          </div>

          <div class="processing-decline-tooltip">
            <h3>Sorry your credit card was declined.</h3>
            <p>Please use a different card or select a alternative payment method.</p>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide class="checkout-box">
        <div class="checkout-box-wrap">

          <div class="checkout-box-title">
            <span *ngIf="selectedOrderNumber && !completed"> Original Order &nbsp; #{{ selectedDocNumber }} </span>
            <span *ngIf="!selectedOrderNumber"> Total </span>
          </div>

          <div class="checkout-box-totals">

            <div class="checkout-box-total">
              <div class="checkout-box-total__key">
                <span>Items</span>
              </div>
              <div class="checkout-box-total__value">
                <span>{{cartListLength}}</span>
              </div>
            </div>

            <div class="checkout-box-total">
              <div class="checkout-box-total__key">
                <span>Units</span>
              </div>
              <div class="checkout-box-total__value">
                <span>{{totalUnits}}</span>
              </div>
            </div>

            <div class="checkout-box-total">
              <div class="checkout-box-total__key">
                <span>Order</span>
              </div>
              <div class="checkout-box-total__value __bold">
                <span>{{totalAmount | CustomCurrency}}</span>
              </div>
            </div>

          </div>

          <button [disabled]="findAllErrors || loading || !cartService.paymentType" *ngIf="isOutOfStockAndSelectedOrderNumber" (click)="placeNew()"
            class="checkout-btn">
            <span *ngIf="loading" class="cart-loader"></span>
            <span *ngIf="!loading">Place New Order</span>
            <span *ngIf="loading">Placing New Order...</span>
          </button>

        </div>
      </ng-template>

    </owl-carousel-o>

    <ng-container *ngIf="isAddressOpen">
      <app-address-mobile [isAddressOpen]="isAddressOpen" [addressType]="addressType" [currentAddress]="currentAddress"
        [addresses]="addresses" [address]="address" (onClose)="closeAddress($event)"
        (selectedAddress)="onSelectedAddress($event)"></app-address-mobile>
    </ng-container>

  </div>
  <!-- Mobile end -->

  <!-- Desktop start -->
  <div class="checkout-boxes" *ngIf="!topService.isMobile" [class.hide]="isCartEmptyAndCompleted">

    <div class="checkout-box">
      <app-address (selectedAddress)="onSelectedAddress($event)" [addressType]="'billing'"
        [currentAddress]="Baddress.addressCode" [addressTitle]="'Bill to Address'" [addresses]="billing"
        [address]="Baddress"></app-address>
    </div>

    <div class="checkout-box">
      <app-address (selectedAddress)="onSelectedAddress($event)" [addressType]="'shipping'"
        [currentAddress]="Saddress.addressCode" [address]="Saddress" [addressTitle]="'Ship to Address'"
        [addresses]="shipping"></app-address>
    </div>

    <div class="checkout-box">
      <!-- Class invalid if no payment method selected -->
      <div class="checkout-box-wrap" [class.invalid]="!cartService.paymentType" [class.declined]="isCreditCardDeclined()">

        <div class="checkout-box-title">
          <span> Payment Type</span>
          <span class="on-invalid"> Select One </span>
        </div>

        <div class="checkout-box-radio">
          <div class="checkout-radio" *ngIf="showPaymentTypeOption(PaymentTypeEnum.terms)">
            <div class="checkout-radio-wrap" (change)="onChoosePaymentType()">
              <input type="radio" id="Terms" name="paymentType" [value]="PaymentTypeEnum.terms" [(ngModel)]="cartService.paymentType">
              <label for="Terms"></label>
            </div>
            <label for="Terms" class="checkout-radio__label">Terms</label>
          </div>

          <div class="checkout-radio" (change)="onChoosePaymentType()" *ngIf="showPaymentTypeOption(PaymentTypeEnum.prepay)" >
            <div class="checkout-radio-wrap">
              <input type="radio" id="Prepay" name="paymentType" [value]="PaymentTypeEnum.prepay" [(ngModel)]="cartService.paymentType">
              <label for="Prepay"></label>
            </div>
            <label for="Prepay" class="checkout-radio__label">Pre-pay</label>
          </div>

          <div  *ngIf="showPaymentTypeOption(PaymentTypeEnum.credit)" class="checkout-radio" (change)="onChoosePaymentType()">
            <div class="checkout-radio-wrap">
              <input type="radio" id="Credit" name="paymentType" [value]="PaymentTypeEnum.credit" [(ngModel)]="cartService.paymentType">
              <label for="Credit"></label>
            </div>
            <label for="Credit" class="checkout-radio__label">Credit Card
              <span>
                {{selectedCreditCard()?.nickname}}
              </span>
            </label>
            <a *ngIf="creditCardList().length && !isUpdatingCreditOrder && cartService.paymentType === PaymentTypeEnum.credit"
            role="button" class="checkout-radio__link" (click)="changeCreditCard()">
              <span>Change</span>
            </a>
            <a *ngIf="userService.isCreditCardListEmpty === 0 && !isUpdatingCreditOrder"
            role="button" class="checkout-radio__link" (click)="changeCreditCard()">
              <span class="badge-new">new</span>
            </a>
          </div>

        </div>
        <div class="processing-decline-tooltip">
          <h3>Sorry your credit card was declined.</h3>
          <p>Please use a different card or select a alternative payment method.</p>
        </div>
      </div>
    </div>

    <div class="checkout-box">

      <div class="checkout-box-wrap">

        <div class="checkout-box-title">
          <span *ngIf="selectedOrderNumber && !completed"> Original Order &nbsp; #{{ selectedDocNumber }} </span>
          <span *ngIf="!selectedOrderNumber"> Total </span>
        </div>

        <div class="checkout-box-totals">

          <div class="checkout-box-total">
            <div class="checkout-box-total__key">
              <span>Items</span>
            </div>
            <div class="checkout-box-total__value">
              <span>{{cartListLength}}</span>
            </div>
          </div>

          <div class="checkout-box-total">
            <div class="checkout-box-total__key">
              <span>Units</span>
            </div>
            <div class="checkout-box-total__value">
              <span>{{totalUnits}}</span>
            </div>
          </div>

          <div class="checkout-box-total">
            <div class="checkout-box-total__key">
              <span>Order</span>
            </div>
            <div class="checkout-box-total__value __bold">
              <span>{{totalAmount | CustomCurrency}}</span>
            </div>
          </div>

        </div>

        <button [disabled]="findAllErrors || loading || !cartService.paymentType" *ngIf="isOutOfStockAndSelectedOrderNumber" (click)="placeNew()"
          class="checkout-btn">
          <span *ngIf="loading" class="cart-loader"></span>
          <span *ngIf="!loading">Place New Order</span>
          <span *ngIf="loading">Placing New Order...</span>
        </button>

      </div>
    </div>

  </div>

  <!-- Order Info start -->
  <div class="checkout-btm" *ngIf="selectedOrderNumber && order?.payment?.id" >
    <ul class="order-info">
      <li>
        <div class="order-info__label">Payment Status</div>
        <div class="order-info__value">
          <span [ngClass]="'badge _' + order?.orderStatus">
            {{ cartService.toPascalCase(order?.orderStatus) }}
          </span>
        </div>
      </li>
      <!-- <li>
        <div class="order-info__label">Authorization</div>
        <div class="order-info__value">{{order?.payment?.authorization}}</div>
      </li> -->
      <!-- <li>
        <div class="order-info__label">Charge Reference</div>
        <div class="order-info__value" *ngIf="order?.payment?.status === 'Applied'">{{order?.payment?.charge}}</div>
      </li> -->
      <li>
        <div class="order-info__label">Order Date</div>
        <div class="order-info__value">{{order?.orderDate | date:"shortDate"}}</div>
      </li>
      <li>
        <div class="order-info__label">Order Date Charged</div>
        <div class="order-info__value" *ngIf="order?.payment?.status === 'Applied'">{{selectedOrder?.payment?.orderDate | date:"shortDate"}}</div>
      </li>
      <li>
        <div class="order-info__label">
          <span *ngIf="order?.payment?.status === 'Applied'">Charge </span>
          <span *ngIf="order?.payment?.status === 'Authorize'">Authorize </span>
          Amount
        </div>
        <div class="order-info__value">{{order?.payment?.amount | CustomCurrency}}</div>
      </li>
      <li>
        <div class="order-info__label">Card Nickname</div>
        <div class="order-info__value">{{order?.payment?.cardName}}</div>
      </li>
    </ul>
  </div>
  <!-- Order Info end -->

  <!-- Desktop end -->
</div>

<div class="cards-modal" [class.mobile]="topService.isMobile" *ngIf="cardListOpen">
  <div class="cards-modal-dialog animated" [class.zoomIn]="cardListOpen" [class.zoomOut]="cardListClose()">
    <div class="cards-modal-content">
      <div class="cards-modal-header">
        <span>Select Credit Card</span>
        <span class="cards-modal-header__close" (click)="closeCardListModal()">
          <img src="assets/images/close.svg" width="24" height="24" alt="close">
        </span>
      </div>
      <div class="cards-modal-body">
        <app-credit-card-list (closeCreditCardListModal)="closeCardListModal()"></app-credit-card-list>
      </div>
    </div>
  </div>
</div>

<app-credit-card *ngIf="showCreateCCModal" [creditCardId]="null"
  (closeCrditCardModal)="closeCreditCardModal($event)"></app-credit-card>
