import { ConfirmationPopupSettings } from './../../../shared/interfaces/IConfirmationPopupSettings';
import { SessionService } from './../../../services/session.service';
import { OfferService } from './../../../user/offers/offer.service';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TopService } from '../../../shared/services/top.service';
import { PageErrorService } from '../../../page-error/page-error.service';
import { ISplitOrderLinePost } from '../../interfaces/ISplitOrderLinePost';
import { IOrder } from '../../interfaces/IOrder';

import * as models from '../../interfaces/model';
import { UserService } from '../../../user/user.service';
import { SharedService } from '../../../shared/shared.service';
import { UtilityService } from '../../../../app/core/utility.service';
import { IOrderListCombo } from '../../interfaces/IOrderListCombo';
import { OrderStatusEnum } from '../../enums/orderStatusEnum';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

const PAGE_SIZE = 25;
@Injectable()
export class OrdersService {
  readonly defaultOrderRequest = `?PageNumber=1&PageSize=${PAGE_SIZE}`;
  restApiVerb = '';
  orderChosen: models.IOrder = {};
  orders: models.IOrder[] = [];
  selected_order_number: number;
  selected_doc_number: number;
  handleFirstOrderLoadRequired = false;
  orderLoaded = new EventEmitter<boolean>();
  orderLoaded2: boolean;
  isOrders = false;

  ordersErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };

  salesOrderStatusArray: models.SalesOrderStatus[];
  defaultStatusColor = '#E7E7E7';
  statusColorMap: { [key in models.SalesOrderStatus]?: { pos: number, color: string; description: string } };
  selectedOrder: models.Order;
  orderListPagination = {
    currentPage: 1,
    pageSize: 25,
    totalCount: 0,
    totalPages: 5
  }


  constructor(
    private http: HttpClient,
    public topService: TopService,
    private pageErrorService: PageErrorService,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private utils: UtilityService,
    private offerservice: OfferService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) { }

  loadOrderHistory(): void {
    this.orders = [];
    this.isOrders = true;
    this.getOrders(this.defaultOrderRequest).subscribe(
      data => {
        this.orderLoaded2 = true;
        this.topService.loading = false;
        this.orderLoaded.emit(true);
        if (data) {
          data.values.forEach(order => {
            if (order.orderStatus === 'open') {
              order.orderStatus = 'Open';
            }
          });
          this.orders = data.values;
          if (this.orders.length === 0) {
            this.isOrders = false;
          }
        }
      },
      error => {
        this.topService.loading = false;
        this.pageErrorService.errorMessage = error.statusText;
        this.pageErrorService.errorCode = error.status;
        this.router.navigate(['page-error']);
      }
    );
  }

  splitLinePost(docEntry: number, lines: ISplitOrderLinePost): Observable<IOrder> {
    this.restApiVerb = 'orders/';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + docEntry + '/split';
    return this.http.put<IOrder>(url, lines);
  }

  cancelOrder(docEntry: number): Observable<number> {
    const url = environment.buyerPortalBaseUrl + 'orders/' + docEntry + '/cancellation';
    return this.http.put<number>(url, 'cancel');
  }

  updateOrder(orderNo): Observable<null> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + 'cart/fromOrder';
    return this.http.post<null>(url, orderNo);
  }

  getOrders(orderData: string = this.defaultOrderRequest): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.IOrderMain>(url).pipe(
      map((orders: models.IOrderMain) => {
        if (orders) {
          orders.values.forEach((order) => {
            if (order.payment.status) {
              order.orderStatus = 'paid';
            }
          });
        }
        return orders;
      })
    );
  }

  getOpenOrders(orderData: string): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders?status=open';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + '&' + orderData;
    return this.http.get<models.IOrderMain>(url);
  }



  chargeOrder(piid: string, amount: number): Observable<any> {
    const url = environment.buyerPortalBaseUrl + 'payments/' + piid + '/applied';
    const param = {
      finalAmount: amount,
    };
    return this.http.post(url, param);
  }

  toPascalCase(s: string): string {
    return this.utils.toTitleCase(s);
  }

  getExternalId(order: IOrder | models.Order) {
    if (!order.externalId) {
      return '--';
    } else if (order.payment?.id && ['Card_Pending', 'Pending Payment'].includes(order.externalId)) {
      return 'CC Paid';
    } else {
      return order.externalId;
    }
  }

  getOpenOrdersShort(docEntry = '') {
    const url = environment.buyerPortalBaseUrl + 'orders/open';
    return this.http.get<IOrderListCombo[]>(url)
      .pipe(
        map((orders) => {
          const openOrders = orders.filter((order) => order.docEntry !== +docEntry)
            .sort((o1, o2) => o2.docNumber - o1.docNumber);
          return openOrders;
        }
        ))
  }

  goToFullOrderPage(docEntry: string): void {
    this.topService.loading = true;
    this.getOrder(docEntry).subscribe(
      (order: models.Order) => {
        this.topService.loading = false;
        // this.orderChosen = order;
        this.selectedOrder = order;
        this.router.navigate(['/buyer/full-order', docEntry]);
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersErrorModal);
      }
    );
  }

  getOrderDetails(docEntry: string): Observable<models.IOrder> {
    this.restApiVerb = 'orders/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.IOrder>(url).pipe(
      map(res => {
        if (res.payment.status) {
          res.orderStatus = 'paid';
        }
        if (res.orderDetails) {
          res.orderDetails.forEach(orderLine => {
            orderLine.description = orderLine.description ? orderLine.description : orderLine.itemName;
          });
        }
        return res;
      })
    );
  }

  getOrder(docEntry: string): Observable<models.Order> {
    this.restApiVerb = 'v2/orders/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.Order>(url).pipe(
      map((order) => {
        if (order.progressStatus === models.SalesOrderStatus.ProcessingStock) {
          order.progressStatus = models.SalesOrderStatus.Processing;
        }
        return order;
      })
    )
  }

  getOrderByDocNumber(docNumber: string): Observable<models.Order> {
    this.restApiVerb = `v2/orders/by-document-number/${docNumber}`;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.Order>(url).pipe(
      map((order) => {
        if (order.progressStatus === models.SalesOrderStatus.ProcessingStock) {
          order.progressStatus = models.SalesOrderStatus.Processing;
        }
        return order;
      })
    )
  }

  getOrderList(orderData: string = this.defaultOrderRequest): Observable<models.OrderListResponse> {
    var defaultOrderListResponse = {
      values: [],
      pagination: this.orderListPagination
      } as models.OrderListResponse;
    this.restApiVerb = 'v2/orders';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.OrderListResponse | null>(url).pipe(
      map((response) => {
        if (!response) { // handle no content case
          return defaultOrderListResponse;
        } 
        if (response.values) {
          response.values = response.values.map(order => {
            if (order.progressStatus === models.SalesOrderStatus.ProcessingStock) {
              order.progressStatus = models.SalesOrderStatus.Processing;
            }
            return order;
          });
        }
        return response;
      }),
      catchError((error) => {
        return of( defaultOrderListResponse); 
      })

    );
  }

  filterAndMapSalesOrderStatus() {
    const userRole = this.sessionService.userRole;
    const filteredSalesOrderStatus = Object.values(models.SalesOrderStatus).filter(
      (status) => typeof status === 'number' && status !== models.SalesOrderStatus.ProcessingStock
    );
    this.salesOrderStatusArray = filteredSalesOrderStatus as models.SalesOrderStatus[];

    this.statusColorMap = {
      [models.SalesOrderStatus.PendingPayment]: { pos: 0, color: '#EB3E43', description: 'Pending payment' },
      [models.SalesOrderStatus.PaymentApproved]: { pos: 1, color: '#F37428', description: 'Payment Approved' },
      [models.SalesOrderStatus.ProcessingTransit]: { pos: 2, color: '#FBA50F', description: 'Processing Transit' },
      [models.SalesOrderStatus.Processing]: { pos: 3, color: '#E9C30C', description: 'Processing' },
      [models.SalesOrderStatus.Ready]: { pos: 4, color: '#B4CC28', description: 'Ready' },
      [models.SalesOrderStatus.Shipped]: { pos: 5, color: '#7ED342', description: 'Shipped' }
    };
  }

  openPdf(e: Event, docEntry: number): void {
    if (e) { e.stopPropagation(); }
    this.topService.loading = true;
    this.restApiVerb = `v2/orders/${docEntry}/pdf`;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        catchError(err => {
          this.topService.loading = false;
          console.error('Error downloading the PDF', err);
          return throwError(() => new Error('Error downloading the PDF'));
        })
      )
      .subscribe(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const fileName = this.getPdfFileName(contentDisposition, docEntry);
        const blob = new Blob([response.body!], { type: 'application/pdf' });
        saveAs(blob, fileName);
        this.topService.loading = false;
      });
  }

  getPdfFileName(contentDisposition: string, docEntry: number): string {
    let fileName = `HUBX-order-${docEntry}.pdf`;
    if (contentDisposition) {
      const parts = contentDisposition.split(";").map(part => part.trim());
      let fileNamePart = parts.find(part => part.startsWith("filename="));
      if (fileNamePart) {
        fileName = fileNamePart.split("=")[1].trim();
        if (fileName.startsWith('"') && fileName.endsWith('"')) {
          fileName = fileName.slice(1, -1);
        }
      }
    }
    return fileName;
  }




}
